<template>
	<div class="note">
        <template v-if="showUserNote">
            <h5>การตั้งชื่อผู้ใช้</h5>
            <ul>
                <li>ตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น</li>
                <li>ห้ามมีเว้นวรรคหรืออักขระพิเศษ ยกเว้น _ หรือ -</li>
            </ul>
        </template>
        <template v-if="showPassNote">
            <h5>การตั้งรหัสผ่าน</h5>
            <ul>
                <li>รหัสผ่านต้องยาว 8 ตัวอักษรขึ้นไป</li>
                <li>ตัวอักษร A-Z ตัวใหญ่ อย่างน้อย 1 ตัวอักษร</li>
                <li>ตัวอักษร a-z ตัวเล็ก อย่างน้อย 1 ตัวอักษร</li>
                <li>ตัวเลข อย่างน้อย 1 ตัวอักษร</li>
            </ul>
        </template>
        <template v-if="showUrlNote">
            <h5>การตั้งชื่อ URL</h5>
            <ul>
                <li>ตัวอักษรภาษาอังกฤษหรือตัวเลขเท่านั้น</li>
                <li>ห้ามมีเว้นวรรคหรืออักขระพิเศษ ยกเว้น _ หรือ -</li>
            </ul>
        </template>
    </div>
</template>

<script>
export default {
	props: {
        showUserNote: {
            type: Boolean,
            required: false,
            default: false
        },
        showPassNote:  {
            type: Boolean,
            required: false,
            default: false
        },
        showUrlNote: {
            type: Boolean,
            required: false,
            default: false
        }
    }
}
</script>

<style lang="scss" scoped>
.note {
    padding: 10px;
    background: #f5f5f5;
    border: 1px solid #ddd;

    h5 {
        font-size: 15px;
    }

    ul {
        margin-bottom: 0;

        li {
            font-size: 12px;
            opacity: 0.8;
        }
    }
}
</style>